// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-ortodoncia-pagina-de-enlaces-js": () => import("./../../../src/pages/ortodoncia-pagina-de-enlaces.js" /* webpackChunkName: "component---src-pages-ortodoncia-pagina-de-enlaces-js" */),
  "component---src-templates-advise-page-js": () => import("./../../../src/templates/advise-page.js" /* webpackChunkName: "component---src-templates-advise-page-js" */),
  "component---src-templates-annex-page-js": () => import("./../../../src/templates/annex-page.js" /* webpackChunkName: "component---src-templates-annex-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-clinic-page-js": () => import("./../../../src/templates/clinic-page.js" /* webpackChunkName: "component---src-templates-clinic-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-dental-tourism-js": () => import("./../../../src/templates/dental-tourism.js" /* webpackChunkName: "component---src-templates-dental-tourism-js" */),
  "component---src-templates-disclaimer-page-js": () => import("./../../../src/templates/disclaimer-page.js" /* webpackChunkName: "component---src-templates-disclaimer-page-js" */),
  "component---src-templates-foreign-patients-page-js": () => import("./../../../src/templates/foreign-patients-page.js" /* webpackChunkName: "component---src-templates-foreign-patients-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-payment-options-page-js": () => import("./../../../src/templates/payment-options-page.js" /* webpackChunkName: "component---src-templates-payment-options-page-js" */),
  "component---src-templates-professionals-page-js": () => import("./../../../src/templates/professionals-page.js" /* webpackChunkName: "component---src-templates-professionals-page-js" */),
  "component---src-templates-specialties-page-js": () => import("./../../../src/templates/specialties-page.js" /* webpackChunkName: "component---src-templates-specialties-page-js" */),
  "component---src-templates-thank-you-page-js": () => import("./../../../src/templates/thank-you-page.js" /* webpackChunkName: "component---src-templates-thank-you-page-js" */)
}

