import "typeface-roboto";
import "typeface-ranga";
import "./src/css/fonts/Bebas/style.css";
import "flag-icon-css/css/flag-icon.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/css/icons/style.css";
import "./src/css/icons/burger/burger.css";
import "react-popupbox/dist/react-popupbox.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./src/css/preloader/preloader.css";
import "./src/css/icons/warning-react/style.css";
import "./src/css/icons/turismo/style.css";
import "./src/css/icons/phones/style.css";
import "./src/css/icons/email-icon/style.css";
import "./src/css/icons/parallax/style.css";
import "./src/css/icons/payment-options/style.css";
import "./src/css/icons/periodoncia/style.css";
import "./src/css/icons/check/style.css";

const onClientEntry = () => {
  window.onload = () => {
    document.getElementById("preloader").style.display = "none";
  };
};

export { onClientEntry };
